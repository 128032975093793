import "./styles.css";
import layout from "./layout.json";
import { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const Profile = ({ x, y, image, onClick }) => {
  const width = 9.6918007366;

  return (
    <div
      className="profile-box"
      style={{
        background: "#ff2100",
        position: "absolute",
        width: `${width}vw`,
        height: `${width}vw`,
        top: `${y}vw`,
        left: `${x}vw`,
        opacity: 0.0,
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      {/* <img
        className="profile-image"
        style={{ width: "100%" }}
        src={`img/${image}`}
      ></img> */}

      <div></div>
    </div>
  );
};

const Logo = ({ width, x, y, image }) => {
  return (
    <div
      style={{
        position: "absolute",
        width: `${width}vw`,
        top: `${y}vw`,
        left: `${x}vw`,
        opacity: 0.9
      }}
    >
      {/* <img style={{ width: "100%" }} key={image} src={`img/${image}`}></img> */}
    </div>
  );
};

const ModalContent = ({
  name,
  date,
  text,
  references,
  profileReferences,
  onClose
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline"
        }}
      >
        <div>
          <h2 style={{ color: "#E92F53", marginBottom: 0 }}>{name}</h2>
          <h3 style={{ marginTop: 0, fontWeight: "normal" }}>{date}</h3>
        </div>
        <button
          onClick={onClose}
          style={{
            border: "none",
            background: "none",
            fontSize: 30,
            color: "#E92F53",
            cursor: "pointer"
          }}
        >
          x
        </button>
      </div>
      <p>{text}</p>
      {profileReferences?.length > 0 && <p>-</p>}
      {profileReferences?.map((ref) => (
        <p key={references[ref - 1]} style={{ fontSize: 10 }}>
          {ref} {references[ref - 1]}
        </p>
      ))}
    </>
  );
};

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)"
  },
  content: {
    background: "#F1E8DE",
    border: "none",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: "30px 50px",
    maxHeight: "80vh"
  }
};

const PinBoard = ({ profiles, logos, onProfileClick }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "46.30742392vw",
        backgroundImage: 'url("img/bg2.png")',
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat"
      }}
    >
      {profiles.map((profile, i) => (
        <Profile
          key={profile.name}
          {...profile}
          onClick={() => onProfileClick(i)}
        />
      ))}
      {logos.map((logo) => (
        <Logo key={logo.image} {...logo} />
      ))}
    </div>
  );
};

function App() {
  const { profiles, references, logos } = layout;
  const [selectedProfile, setSelectedProfile] = useState(null);
  const currentProfile = profiles[selectedProfile];

  return (
    <div className="App">
      <PinBoard
        profiles={profiles}
        logos={logos}
        onProfileClick={setSelectedProfile}
      />
      <Modal
        isOpen={Boolean(currentProfile)}
        onRequestClose={() => setSelectedProfile(null)}
        style={modalStyles}
      >
        <ModalContent
          {...currentProfile}
          references={references}
          profileReferences={currentProfile?.references}
          onClose={() => setSelectedProfile(null)}
        />
      </Modal>
    </div>
  );
}

export default App;
